import Vue from 'vue'
import App from './App.vue'

import Vant from 'vant'
import VueLazyload from 'vue-lazyload'
import 'vant/lib/index.css'
import 'animate.css';
import store from "./store/index.js"
import router from "./router/index.js"
import http from './lib/http'
import "./assets/css/reset.css"
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.use(Vant)
Vue.use(VueLazyload)
Vue.config.productionTip = false
Vue.prototype.$http = http;
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
