export default {
  userInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  setSocketMsg(state, messag) {
    state.messag = messag;
  },
  socketConnect(state, socket) {
    state.socket = socket;
  },
  setCurrentPid(state, config) {
    state.accountInfo = config;
    if (config.pid && config.pid != state.pid) {
      state.pid = config.pid;
    } else if(!config.pid){
      state.pid = '';
      if(state.socket){
        state.socket.destroy();
        state.socket = null
      }
    }
  },
  setAccountList(state, list) {
    state.accountList = list;
  },
  updCustomConf(state, { itemId, config }) {
    let itemIdx = state.accountInfo.liveConfig.item.findIndex(data => data.id === itemId)
    state.accountInfo.liveConfig.item[itemIdx] = Object.assign({}, state.accountInfo.liveConfig.item[itemIdx], config);
  },
  message(state, payload) {
    state.message = payload;
  }
};