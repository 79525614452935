import VueRouter from "vue-router";
import Vue from "vue";
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: "Index",
    redirect: "/user",
    component: () => import('@/pages/Index/index.vue')
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("@/pages/Product/index.vue"),
    meta: {
      title: "商品",
      showTabbar: true, // 底部导航
      showMenu: true // 切换抖音号
    }
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/pages/User/index.vue"),
    meta: {
      title: "我的",
      showTabbar: true,
      showMenu: true
    }
  },
  {
    path: "/template",
    name: "Template",
    component: () => import("@/pages/Template/index.vue"),
    meta: {
      title: "直播间搭建",
      showTabbar: true,
      showMenu: true
    }
  },
  {
    path: "/preparation-process",
    name: "Template",
    component: () => import("@/pages/PreparationProcess/index.vue"),
    meta: {
      title: "开播前准备",
      showTabbar: false,
      showMenu: false,
      isNewWrap: true
    }
  },

  {
    path: "/wxlogin",
    name: "WxLogin",
    component: () => import("@/pages/LoginWx/index.vue"),
    meta: {
      title: "微信登录提示",
      showTabbar: false,
      showMenu: false
    }
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/pages/Download/index.vue"),
    meta: {
      title: "下载",
    }
  },
  {
    path: "/words",
    name: "Words",
    component: () => import("@/pages/words/index.vue"),
    meta: {
      title: "话术",
      isNewWrap: true
    },
    children: [
      {
        path: "list", component: () => import("@/pages/words/list.vue"), meta: {
          title: "话术列表",
          isNewWrap: true
        }
      },
      {
        path: ":id", component: () => import("@/pages/words/detail.vue"), meta: {
          isNewWrap: true
        }
      },
    ]
  },

];

export default new VueRouter({
  mode: "history",
  routes,
});
