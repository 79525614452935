<template>
  <van-overlay z-index="11" :show="value">
    <div class="qrWrap">
      <div class="qrBox">
        <i
          v-if="showClose"
          class="van-icon van-icon-close isActive"
          @click.stop="$emit('input', false)"
        />
        <p v-if="!showClose">{{ tipText }}</p>
        <div class="qrcode">
          <img src="./../../assets/img/kefu.png" alt="客服微信" />
        </div>
        <span>长按添加 联系导师</span>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    value: {
      default: () => false,
    },
    showClose: {
      default: () => false,
    },
    tipText: {
      default: () => "请添加导师，开通权限后可查看",
    },
  },
};
</script>

<style lang="scss" scoped>
.qrWrap {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .qrBox {
    width: r(600);
    padding: r(100) 0;
    background-color: #fff;
    position: relative;
    p,
    span {
      font-size: r(30);
      line-height: r(40);
      text-align: center;
      color: #232323;
    }

    p {
      margin-bottom: r(50);
    }

    .qrcode {
      width: r(300);
      height: r(300);
      background-color: #ccc;
      margin: 0 auto;

      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    span {
      display: block;
      margin-top: r(20);
    }
  }

  .van-icon {
    font-size: r(80);
    font-weight: bold;
    color: #fff;
    position: absolute;
    right: 0;
    top: r(-100);
  }
}
</style>