<template>
  <div class="changeDyMenu">
    <header :class="{ isChange: showAccountList }" @click="changeShowAccount">
      <template v-if="accountInfo">
        <img :src="accountInfo.headimg || DEFAULT_IMG" />
        <div class="menu">
          <strong>{{ currentName }}</strong>
          <!-- <span>粉丝数: {{ accountInfo.fans }}</span> -->
        </div>
      </template>
      <i
        class="van-icon van-icon-play"
        v-if="accountList.length > 1 || isShowAdd"
      />
    </header>
    <section @click="showAccountList = false">
      <template v-if="accountList && accountList.length">
        <div
          v-for="(item, index) in accountList"
          :key="item.id"
          class="menuItem"
          @click.stop="changeAccount(item)"
        >
          <img :src="item.headimg || DEFAULT_IMG" />
          <div>
            <strong>{{
              item.nickname ||
              `账号${index + 1}-${
                item.extra && item.extra.douyinid
                  ? item.extra.douyinid
                  : userInfo.name
              }`
            }}</strong>
            <!-- <span>粉丝数: {{ item.fans }}</span> -->
          </div>
          <i
            class="van-icon"
            :class="{
              'van-icon-success': accountInfo && item.id == accountInfo.id,
            }"
          />
        </div>
      </template>
      <div class="menuItem" v-if="isShowAdd">
        <div class="add-new" @click="addNew">
          <van-icon name="add-o" />添加账号
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: {
    isShowAdd: Boolean,
  },
  name: "DYAccount",
  data() {
    return {
      showAccountList: false,
    };
  },

  computed: {
    ...mapState(["accountList", "pid", "accountInfo", "DEFAULT_IMG","userInfo"]),
    currentName() {
      if (this.accountInfo && this.accountInfo.id) {
        if (this.accountInfo.nickname) {
          return this.accountInfo.nickname;
        } else if (this.accountInfo.extra && this.accountInfo.extra.douyinid) {
          let idx = this.accountList.findIndex(
            (item) => item.id === this.accountInfo.id
          );
          return `账号${idx + 1}-${this.accountInfo.extra.douyinid}`;
        } else {
          let idx = this.accountList.findIndex(
            (item) => item.id === this.accountInfo.id
          );
          return `账号${idx + 1}-${this.userInfo.name}`;
        }
      } else {
        return "添加账号";
      }
    },
  },

  created() {},

  methods: {
    ...mapActions(["getCurrentConfig", "connectSocket"]),
    ...mapMutations(["setCurrentPid"]),
    async changeAccount(item) {
      if (item.pid === this.pid) return;
      if (item.id) {
        let ret = await this.getCurrentConfig(item);
        if (ret) {
          this.connectSocket(this.pid);
        }
      } else {
        this.setCurrentPid(item);
        localStorage.setItem("XZM-ID", item.id);
      }
      this.showAccountList = false;
    },

    changeShowAccount() {
      if (this.accountList.length > 1 || this.isShowAdd) {
        this.showAccountList = !this.showAccountList;
      }
    },
    addNew() {
      this.$emit("newAccount");
    },
  },
};
</script>

<style lang="scss" scoped src="./index.scss">
</style>