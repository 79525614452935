<template>
  <div id="app">
    <router-view v-if="isWord || isNewWrap" />
    <template v-else>
      <!--切换微信账号-->
      <div class="accountBtn" @click="logout">
        <van-icon name="manager" size="30" />
      </div>
      <!-- 选择抖音号 -->
      <DYAccount v-if="showMenu && accountList && accountList.length" />

      <div id="pageWrap">
        <!-- 防止未获取到pid时，页面获取数据失败 -->
        <router-view v-if="pid || showRouterView"></router-view>
      </div>

      <!-- 底部导航 -->
      <FooterNav v-if="showTabbar" />

      <!-- 联系客服弹出层 -->
      <ContactCustom v-model="showQrcode" />
    </template>
  </div>
</template>

<script>
import DYAccount from "@/components/DYAccount/index.vue";
import FooterNav from "@/components/FooterNav/index.vue";
import ContactCustom from "@/components/ContactCustom/index.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import VConsole from "vconsole";
export default {
  name: "App",
  components: {
    DYAccount,
    FooterNav,
    ContactCustom,
  },

  watch: {
    $route(to) {
      if (to.name === "WxLogin") {
        this.showRouterView = true;
      }
      const {
        title = "小竹猫达人中控",
        showTabbar = false,
        showMenu = false,
        isNewWrap = false,
      } = to.meta;
      document.title = title;
      this.showTabbar = showTabbar;
      this.showMenu = showMenu;
      this.isNewWrap = isNewWrap;
    },
  },
  data() {
    return {
      showTabbar: false,
      showMenu: false,
      showQrcode: false,
      showRouterView: false,
      vconsole: null,
      isNewWrap: false, //是否使用新的模版，就是旧的内容是带顶部账号和底部导航的
      isWord: false, //话术页面，不需要登录
    };
  },
  async created() {
    if (process.env.VUE_APP_VCONSOLE) {
      this.vconsole = new VConsole({ theme: "dark" });
    }
    let sid = localStorage.getItem("XZM-H5-Token");
    if (sid && sid === "11|QALZn1Mj5W3DHG74tDzFJrXi4Etj5LWHrqi3Ns7L") {
      localStorage.removeItem("XZM-H5-Token");
    }
    if (
      location.href.indexOf("/words") > -1 ||
      location.href.indexOf("/download") > -1
    ) {
      this.isWord = true;
      return;
    } else {
      this.isWord = false;
    }
    if (process.env.VUE_APP_TOKEN) {
      localStorage.setItem("XZM-H5-Token", process.env.VUE_APP_TOKEN);
    } else {
      if (!this.isWeixin) {
        return this.login();
      }
      let code = location.search.match(/code=([^&]*)?/);
      let token = localStorage.getItem("XZM-H5-Token");
      if (code && !token) {
        await this.loginByCode(code[1]);
      }
    }
    await this.checkLogin();
    if (
      process.env.VUE_APP_ENV !== "development" &&
      this.userInfo &&
      (this.userInfo.id === 5 || this.userInfo.id === 106)
    ) {
      this.vconsole = new VConsole({ theme: "dark" });
    }
    this.wxInit();
    await this.initDyAccount();
  },
  beforeDestroy() {
    if (this.vconsole) {
      this.vconsole.destroyed();
    }
  },
  computed: {
    ...mapState(["isWeixin", "accountList", "pid", "socket", "userInfo"]),
  },
  methods: {
    ...mapActions([
      "checkLogin",
      "loginByCode",
      "login",
      "connectSocket",
      "getUserDyAccount",
      "getCurrentConfig",
      "wxInit",
    ]),
    ...mapMutations(["setCurrentPid"]),
    // 初始化微信号绑定的抖音号列表
    async initDyAccount() {
      await this.getUserDyAccount();
      const hisID = localStorage.getItem("XZM-ID"); //上一个选择的历史id
      console.log(">>>>xzm id", hisID, this.accountList);
      let account = this.accountList.find((item) => item.id == hisID);
      let current = null;
      if (account) {
        current = account;
      } else if (this.accountList && this.accountList.length) {
        current = this.accountList[0]; //默认给第一个
      } else {
        this.showQrcode = true;
        return;
      }
      if (
        this.$route.path !== "/preparation-process" &&
        (current.stage === null || current.stage === "s10_finished")
      ) {
        await this.getCurrentConfig(current);
        if (this.pid) {
          this.connectSocket(this.pid);
          document.addEventListener("visibilitychange", () => {
            if (!document.hidden && this.socket && !this.socket.isConnected()) {
              this.socket.reconnect(this.pid);
            }
          });
        }
      } else {
        this.setCurrentPid(current);
      }
    },
    logout() {
      this.$dialog
        .confirm({
          message: "确定要重新登录吗？",
          confirmButtonText: "重新登录",
        })
        .then(() => {
          localStorage.removeItem("XZM-H5-Token");
          location.href = location.origin + location.pathname;
        });
    },
  },
};
</script>

<style lang="scss">
.isActive {
  &:active {
    opacity: 0.5;
  }
}

html,
body,
#app {
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}

#app {
  display: flex;
  flex-direction: column;
  .accountBtn {
    position: absolute;
    z-index: 12;
    left: r(10);
    bottom: r(10);
    opacity: 0.5;
  }
}

#pageWrap {
  flex: 1;
  overflow: hidden;
}
</style>