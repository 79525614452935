const DEFAULT_IMG = require('@/assets/img/logo.png')
// import OSS from "ali-oss";
// const ossRef = new OSS({
//   region: process.env.VUE_APP_ALIYUN_REG,
//   accessKeyId: process.env.VUE_APP_ALIYUN_ACK,
//   accessKeySecret: process.env.VUE_APP_ALIYUN_ACS,
//   bucket: process.env.VUE_APP_ALIYUN_BUK
// });
export default {
  isWeixin: /MicroMessenger/i.test(window.navigator.userAgent),
  userInfo: null, //当前用户的信息
  socket: null, //当前的socket对象
  accountList: [], // 绑定的抖音号列表
  accountInfo: {}, // 当前选中的抖音号
  pid: null, //当前顶部的选中pid,
  DEFAULT_IMG,
  /**
   * {
   * event:String，//事件名称
   * data:Object，//事件数据
   * }
   */
  message: null,// 用于接收socket 下发的消息，其他组件可以watch这个变化，来监听消息
  // ossRef
};