import axios from 'axios';
import { Notify } from 'vant';
import store from '@/store/index';

const PATH_ENV = process.env.VUE_APP_API_PATH;
console.log('>>>> PATH_ENV ', PATH_ENV)
const TOKEN_KEY = 'XZM-H5-Token'
const instance = axios.create({
  baseURL: PATH_ENV,
  timeout: 5000,
  responseType: 'json',
  responseEncoding: 'utf8',
});
// 获取token
instance.interceptors.request.use((config) => {
  const Token = localStorage.getItem(TOKEN_KEY);
  if (Token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${Token}`;
  } else {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = '';
  }

  return config;
});

// 统一处理401
instance.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem(TOKEN_KEY)
    store.dispatch('login')
    return Promise.reject(error);
  }
  if (error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    Notify({ type: 'danger', message: error.response.data.message });
  }
  return Promise.reject(error);
});
const Get = (path, params) => instance.request(
  {
    method: 'get',
    url: path,
    params,
  },
);
const Post = (path, data,option) => instance.request(
  {
    method: 'post',
    url: path,
    data,
    ...option
  });
const Put = (path, data) => instance.request(
  {
    method: 'put',
    url: path,
    data,
  });
const Delete = (path, data) => instance.request(
  {
    method: 'delete',
    url: path,
    data,
  });
export default {
  get: Get,
  post: Post,
  put: Put,
  delete: Delete,
  request: instance,
};
