import $http from '@/lib/http.js'
import XZMService from 'xzm-service-client'
import { Dialog } from 'vant';
const APPID = process.env.VUE_APP_APPID;
const isTest = process.env.VUE_APP_ENV === 'development';
const initUrl = window.location.href.split('#')[0];//为什么要记录这个url,因为对于ios而言，公众号只记录进入是的页面，大概率就是首页，这就导致wxsdk初始化的时候，可能拿到的url 和页面实际的url不一致，会签名错误(ios真机)，我这边首页会自动redirect
export default {
  async checkLogin({ dispatch, commit }) {
    let sid = localStorage.getItem('XZM-H5-Token');
    if (!sid) {
      dispatch('login');
      return;
    }
    let me = await $http.get('/me');
    console.log('>>> me ', me)
    commit('userInfo', me)
  },
  async login({ state }) {
    if (state.isWeixin) {
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    } else if (location.pathname.indexOf('/wxlogin') > -1) {
      return
    } else {
      location.href = '/wxlogin'
    }
  },
  async sendCommandToApp({ state }, { command, params, needBack = false }) {
    if (!state.socket || !state.socket.isConnected()) {
      console.log('>>> socket disconnected')
      return
    }
    if (needBack) {
      return await state.socket.sendForBack(command, params)
    } else {
      state.socket.send(command, params)
    }
  },
  async loginByCode(store, code) {
    console.log('>>>> code ', code);
    let ret = await $http.get('/login', { code });
    console.log('>>>> login by code ', ret)
    localStorage.setItem('XZM-H5-Token', ret.token)
  },
  connectSocket({ commit, state }, planId) {
    if (!planId) return;
    if (state.socket) {
      state.socket.reconnect(planId)
    } else {
      let token = localStorage.getItem("XZM-H5-Token")
      const socket = new XZMService('h5', planId, token, isTest);
      //监听事件
      socket.on("liveState", (params) => {
        commit("message", {
          event: "liveState",
          params
        })
      })
      //监听事件
      socket.on("media", (params) => {
        commit("message", {
          event: "media",
          params
        })
      })
      //监听事件
      socket.on("productsList", (params) => {
        commit("message", {
          event: "productsList",
          params
        })
      })
      // socket.on("connect_error", (event, params) => {
      //   // commit("messsage", {
      //   //   event,
      //   //   params
      //   // })
      //   console.log('>>>> connect_error')
      // })
      // socket.on('connect',()=>{
      //   console.log('>>>> connect')
      // })
      commit('socketConnect', socket);
    }
  },

  async getUserDyAccount({ commit }) {
    try {
      let accountList = await $http.get("/devices");
      if (!accountList || !accountList.length) {
        return;
      }
      //对于开播流程页面，可以展示所有状态的账号，对于其他页面，只能展示可开播状态的账号
      if (!location.pathname.includes('/preparation-process')) {
        accountList = accountList.filter(item => item.stage === null || item.stage === "s10_finished")
      }
      commit('setAccountList', accountList);
    } catch (err) {
      console.log('>>>devices error', err);
    }
  },
  //获取当前达人的配置数据
  async getCurrentConfig({ commit }, account) {
    try {
      let res = await $http.get(`/devices/${account.id}/strapi-live-config`)
      commit('setCurrentPid', Object.assign({}, account, { liveConfig: res, pid: res.id }))
      localStorage.setItem('XZM-ID', account.id)
      return true
    } catch (e) {
      console.log('>>> getCurrentConfig error ', e)
      if (e && e.response && e.response.status == 404) {
        Dialog.alert({ message: `${account.nickname || '该达人'} 没有直播计划，请联系客服` })
      }
      commit('setCurrentPid', Object.assign({}, account))
      return false;
    }
  },

  //调整配置
  async setConfig({ commit, state }, { itemId, config }) {
    await $http.put(`/devices/${state.accountInfo.id}/items/${itemId}`, config)
    commit('updCustomConf', itemId, config)
  },

  //调整参数
  async wxInit() {
    try {
      let isIphone = window.navigator.userAgent.indexOf('iPhone') > -1;
      let ret = await $http.get("/jssdk", {
        jsApiList: [
          "stopRecord",
          "startRecord",
          "uploadVoice",
          "playVoice",
          "stopVoice",
          "pauseVoice",
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "openAddress"
        ].join(","),
        url: isIphone ? initUrl : window.location.href.split('#')[0],
      });
      console.log(">>>> wx", ret);
      wx.config(ret);
      wx.error(function (res) {
        console.log('>>>> wx error res', res)
      })
    } catch (e) {
      console.log('>>>>wxconfig config catch ', e)
    }
  }
};